import { Box } from "@mui/material";
import React from "react";

export default function Contact() {
  return (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      <h2>Contact me coming soon....</h2>
    </Box>
  );
}
