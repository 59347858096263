import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import styles from "./Header.module.css"

function Header() {
  return (
    <Box className={styles.header_container}>
      <Link to={"/"} className={styles.title}>--</Link>
      <Box className={styles.header_nav_links}>
        <Link to={"/projects"}>Projects</Link>
        <Link to={"/blog"}>Blog</Link>
        <Link to={"/contact"}>Contact me</Link>
        <Link to={"/about"}>About</Link>
        <Link to={"https://github.com/v-sai"}>
          <GitHubIcon fontSize="large"/>
        </Link>
        <Link to={"https://www.linkedin.com/in/saivaddemani"}>
          <LinkedInIcon fontSize="large"/>
        </Link>
      </Box>
    </Box>
  );
}

export default Header;
