import React from 'react'
import styles from "./Footer.module.css"
import { Box } from '@mui/material'

function Footer() {
  return (
    <Box className={styles.footer_container}>
      <h5>Footer</h5>
    </Box>
  )
}

export default Footer