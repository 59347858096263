import React, { useState } from "react";
import "./SearchBar.css";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import Filters from "./Filters";

function SearchBar() {
  const [searchKey, setSearchKey] = useState("");
  const handleSeachKey = (e) => {
    setSearchKey(e.target.value);
  };
  return (
    <>
      <div className="search_container">
        <div className="search_bar_n_btn">
          <input
            type="text"
            placeholder="Search..."
            onChange={handleSeachKey}
            value={searchKey}
            className="search_input"
          />
          <IconButton>
            <SearchIcon />
          </IconButton>
        </div>
        <Filters />
      </div>
    </>
  );
}

export default SearchBar;
