import React from 'react'
import "./Projects.css"
import SearchBar from './SearchBar'
import ProjectsList from './ProjectsList'

function Projects() {
  return (
    <div className='projects_container'>
      <SearchBar />
      <ProjectsList />
    </div>
  )
}

export default Projects