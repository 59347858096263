import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../shared/Header/Header";
import Footer from "../shared/Footer/Footer";
import { Box } from "@mui/material";

function Layout() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Header />
      <Box component="main" sx={{ display:"flex",flexGrow: 1,overflow: 'auto' }}>{<Outlet />}</Box>
      <Footer />
    </Box> 
  );
}

export default Layout;
