import React from 'react'
import "./Filters.css"
import { Button } from '@mui/material'

function Filters() {
  return (
    <div className='filters_holder'>
        <Button>Apply Filters</Button>
    </div>
  )
}

export default Filters