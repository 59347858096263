import { Box } from "@mui/material";
import React from "react";

function About() {
  return (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      <h2>About me Coming soon....</h2>
    </Box>
  );
}

export default About;
