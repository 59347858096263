import { Box } from "@mui/material";
import React from "react";
import styles from "./Home.module.css";
import programmerImg from "../shared/images/undraw_programming.svg";
import HtmlImg from '../shared/images/html.jpg';
import MuiImg from '../shared/images/mui.png';
import CssImg from '../shared/images/css.png';
import JsImg from '../shared/images/js.png';
import TsImg from '../shared/images/ts.png';
import ReactImg from '../shared/images/react.png';
import ReduxImg from '../shared/images/redux.png';
import GitImg from '../shared/images/git.png';
import NodeImg from '../shared/images/node.png';
import MongodbImg from '../shared/images/mongodb.png';

function Home() {
  const skills = [
    {
      name: "HTML",
      img: HtmlImg,
    },
    {
      name: "CSS",
      img: CssImg,
    },
    {
      name: "JavaScript",
      img: JsImg,
    },
    {
      name: "TypeScript",
      img: TsImg,
    },
    {
      name: "React",
      img: ReactImg,
    },
    {
      name: "Redux",
      img: ReduxImg,
    },
    {
      name: "Material-UI",
      img: MuiImg,
    },
    {
      name: "Git",
      img: GitImg,
    },
    {
      name: "NodeJs",
      img: NodeImg,
    },
    {
      name: "MongoDB",
      img: MongodbImg,
    },
  ];
  return (
    <Box className={styles.home_container}>
      <Box className={styles.home_top_hero_section}>
        <Box className={styles.home_title}>
          <h2>I'm</h2>
          <h1 className={styles.name}>Sai Vaddemani</h1>
          <h3>Transforming Ideas into Reality</h3>
        </Box>
        <Box className={styles.home_hero}>
          <img src={programmerImg} alt="hero" className={styles.hero} />
        </Box>
      </Box>
      <Box className={styles.home_services_section}>
        <h1 className={styles.skills_title}>My Toolbox</h1>
        <h3>
          Equipped with the latest technologies, I turn complex problems into
          elegant web solutions. My expertise includes
        </h3>
        <Box className={styles.skills}>
          {skills.map((item) => {
            return (
              <Box className={styles.skill}>
                {/* <h3>{item.name}</h3> */}
                <img src={item.img} alt={item.name} />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default Home;
