import { Box } from "@mui/material";
import React from "react";

export default function Blog() {
  return (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      <h2>Blog coming soon....</h2>
    </Box>
  );
}
