import React from "react";
import "./ProjectsList.css";

function ProjectsList() {
  const projects = [
    {
      id: 1,
      name: "E-commerce Website",
      description:
        "Developed a fully functional e-commerce website with user authentication and payment processing.",
      image: "https://fastly.picsum.photos/id/85/1280/774.jpg?hmac=h_HHpvfhMmLP6uOSrHS7HSlXVRuMKfBbc8HFKd1Acv4",
      skills: [
        "HTML, CSS, JavaScript",
        "React",
        "Node.js",
        "Express",
        "MongoDB",
      ],
      date: "January 2022",
      client: "ABC Retail Inc.",
      role: "Full Stack Developer",
      url: "https://www.example.com/project1",
      github: "https://github.com/yourusername/project1",
      achievements:
        "Increased online sales by 30% within the first quarter of launch.",
      challenges: "Overcame performance issues to ensure fast page load times.",
      testimonials:
        "The team's work was exceptional, and we saw a significant boost in online sales.",
      tags: ["E-commerce", "Web Development"],
    },
    {
      id: 2,
      name: "Mobile App Redesign",
      description:
        "Redesigned the user interface of a mobile app to improve user experience and engagement.",
      image: "https://fastly.picsum.photos/id/85/1280/774.jpg?hmac=h_HHpvfhMmLP6uOSrHS7HSlXVRuMKfBbc8HFKd1Acv4",
      skills: ["UI/UX Design", "Adobe XD", "Usability Testing"],
      date: "April 2021",
      client: "XYZ Tech Solutions",
      role: "UI/UX Designer",
      url: "https://www.example.com/project2",
      github: null,
      achievements: "Increased user retention by 25% after the redesign.",
      challenges:
        "Ensuring a consistent design across different mobile devices.",
      testimonials:
        "The new design has made our app much more user-friendly. Great job!",
      tags: ["UI/UX Design", "Mobile App"],
    },
    {
      id: 3,
      name: "Mobile App Redesign",
      description:
        "Redesigned the user interface of a mobile app to improve user experience and engagement.",
      image: "https://fastly.picsum.photos/id/85/1280/774.jpg?hmac=h_HHpvfhMmLP6uOSrHS7HSlXVRuMKfBbc8HFKd1Acv4",
      skills: ["UI/UX Design", "Adobe XD", "Usability Testing"],
      date: "April 2021",
      client: "XYZ Tech Solutions",
      role: "UI/UX Designer",
      url: "https://www.example.com/project2",
      github: null,
      achievements: "Increased user retention by 25% after the redesign.",
      challenges:
        "Ensuring a consistent design across different mobile devices.",
      testimonials:
        "The new design has made our app much more user-friendly. Great job!",
      tags: ["UI/UX Design", "Mobile App"],
    },
    {
      id: 4,
      name: "Mobile App Redesign",
      description:
        "Redesigned the user interface of a mobile app to improve user experience and engagement.",
      image: "https://fastly.picsum.photos/id/85/1280/774.jpg?hmac=h_HHpvfhMmLP6uOSrHS7HSlXVRuMKfBbc8HFKd1Acv4",
      skills: ["UI/UX Design", "Adobe XD", "Usability Testing"],
      date: "April 2021",
      client: "XYZ Tech Solutions",
      role: "UI/UX Designer",
      url: "https://www.example.com/project2",
      github: null,
      achievements: "Increased user retention by 25% after the redesign.",
      challenges:
        "Ensuring a consistent design across different mobile devices.",
      testimonials:
        "The new design has made our app much more user-friendly. Great job!",
      tags: ["UI/UX Design", "Mobile App"],
    },
  ];

  return (
    <div className="project_list_container">
      {projects.map((project) => {
        return <div className="project_holder" key={project.id}>
          <img src={project?.image} alt={project?.image} />
          <h4>{project?.name}</h4>
        </div>;
      })}
    </div>
  );
}

export default ProjectsList;
